body {
    color: white;
    background-color: black;
}

.btn {
    background-color: #d5b15e;
    font-weight: 600;
}

#menu {
    padding-top: 2em;
}

#menu a.icon {
    text-align: center;
    text-decoration: none;
    color: white;
}

#menu a.icon div {
    background-size: 70px 70px;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 80px;
    margin: 0 auto;
}

#menu a.icon div.logo {
    background-image: url("../public/logo.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    min-height: 160px;
}

#menu .youtube {
    background-image: url("../public/youtube.png");
}

#menu .options_sweeps {
    background-image: url("../public/options_sweeps.png");
}

#menu .manage_plans {
    background-image: url("../public/manage_plans.png");
}

#menu .discord {
    background-image: url("../public/discord.png");
}

#hero {
    background-image: url("../public/open_book.jpg");
    background-size: cover;
    min-height: 600px;
}

#hero-info {
    background-color: rgba(0, 0, 0, 0.8);
    text-align: center;
    padding: 2em;
}

#hero-info h1 {
    margin-bottom: 1em;
    color: #d5b15e;
}

#hero-info p {
    margin-bottom: 2em;
}

#card {
    text-align: center;
    min-height: 60vh;
    justify-content: space-between;
}

#card button {
    margin-top: 2rem;
    height: 100%;
    min-width: 50%;
}

#card h1 {
    margin-top: 1em;
    text-align: center;
    margin-bottom: 1em;
}

#card ul {
    margin-top: 1em;
    list-style-type: none;
    text-align: center;
    padding-left: 0;
}

#card #person {
    background-repeat: no-repeat;
    background-position: center;
    background-size: 300px 200px;
    min-height: 200px;
}

#card.trader #person {
    background-image: url("../public/Trader_White.svg");
    border-right: 3px solid grey;
}

#card.analyst #person {
    background-image: url("../public/Analyst_White.svg");
    border-right: 3px solid grey;
}

#card.quant #person {
    background-image: url("../public/Quant_White.svg");
}

#card h2 {
    text-align: center;
    font-size: 1.3em;
}

#card span {
    display: block;
    text-align: center;
}

#cardmobile {
    text-align: center;
    justify-content: space-between;
}

#cardmobile ul {
    margin-top: 1em;
    list-style-type: none;
    text-align: center;
    font-size: 14px;
}
#cardmobile li {
    padding-bottom: 10px;
}

#cardmobile #person {
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 200px 150px;
    min-height: 150px;
}

#cardmobile.trader #person {
    background-image: url("../public/Trader_White.svg");
}

#cardmobile.analyst #person {
    background-image: url("../public/Analyst_White.svg");
}

#cardmobile.quant #person {
    background-image: url("../public/Quant_White.svg");
}

#cardmobile #analyst_h2 {
    margin-top: 11px;
}

#cardmobile #analyst_or {
    margin-top: 5px;
    margin-bottom: 3px;
}

#cardmobile #quant_button {
    padding-top: 5px;
}
#cardmobile #image {
    margin-bottom: 5px;
    padding-top: 5px;
}

#cardmobile.body {
    border-bottom: 3px solid grey;
}

.padding {
    padding-top: 20px;
}

#more {
    text-align: center;
    border-top: 3px solid grey;
    padding: 3em;
    margin-top: 1em;
}

#more_detail {
    text-align: center;
    align-items: center;
    padding-bottom: 2em;
    border-bottom: 3px solid grey;
}

#more_detail ul {
    list-style-type: none;
    line-height: 2em;
}

@media only screen and (min-width: 992px) {
    #more_detail ul.text {
        padding-left: 15rem;
    }

    #more_detail ul.coin {
        padding-right: 15rem;
    }

    #more_detail img {
        margin-right: 14rem;
    }
}

#more_detail img {
    display: inline-block;
    margin-bottom: 1em;
    width: 100px;
    height: auto;
}

#more_detail .sub_text {
    color: grey;
    font-size: 80%;
    line-height: 80%;
}

#partners {
    text-align: center;
    padding: 3em;
    margin-top: 1em;
}

#partners_detail {
    text-align: center;
}

#partners_detail img {
    display: inline-block;
    margin-bottom: 0;
    width: 200px;
    height: auto;
}

#partners_detail h2 {
    font-size: 110%;
}

#partners_detail p {
    color: darkgray;
    font-size: 80%;
}

.ft-brand {
    margin-top: 1.5vh;
    font-weight: 500;
    font-size: large;
    color: grey;
    text-align: center;
}

.ft-brand-img {
    margin-right: 1vw;
}
.ft-disc {
    color: grey;
    margin-top: 2vh;
    padding: 1.5vh;
    text-align: center;
}
.ft-divider {
    border: 0.25px solid rgba(87, 87, 87, 0.808);
    margin: 7em;
    margin-bottom: auto;
    margin-top: 2em;
}
.footer-wrapper {
    align-content: center;
    background-color: rgb(15, 15, 15);
    padding-top: 1vh;
    color: white;
    min-height: 100%;
    margin-top: 15vh;
}
.lnk {
    text-decoration: none;
    text-emphasis: none;
    color: white;
}
.lnk:hover {
    text-decoration: none;
    text-emphasis: none;
    color: white;
}
.trader-space {
    min-height: 6vh;
}
.quant-space {
    min-height: 8vh;
}
.legal-lnk {
    font-size: medium;
    color: rgb(175, 175, 175);
}
.legal-lnk:hover {
    color: rgb(175, 175, 175);
    font-size: medium;
    text-emphasis: none;
    text-decoration: none;
}
.legal {
    margin-top: 5vh;
    margin-left: 15vw;
}

.marginless {
    padding: 0% 0% 0% 0%;
}

.pb-50 {
    padding-bottom: 50px;
}
.pt-70 {
    padding-top: 70px;
}

.mb-15 {
    margin-bottom: 15px;
}

.subscribe-text span {
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    letter-spacing: 5px;
}
.subscribe-text h2 {
    color: #fff;
    font-size: 36px;
    font-weight: 300;
    margin-bottom: 0;
    margin-top: 6px;
}
.subscribe-wrapper {
    overflow: hidden;
}
.mb-15 {
    margin-bottom: 15px;
}
.subscribe-form {
    padding-top: 15px;
}
.subscribe2-wrapper .subscribe-form input {
    background: none;
    border: 1px solid #fff;
    border-radius: 30px;
    color: #fff;
    display: inline-block;
    font-size: 15px;
    font-weight: 300;
    height: 57px;
    margin-right: 17px;
    padding-left: 35px;
    width: 70%;
    cursor: pointer;
}

.subscribe2-wrapper .subscribe-form button {
    background: #d5b15e;
    border: none;
    border-radius: 30px;
    color: black;
    display: inline-block;
    font-size: 18px;
    font-weight: 400;
    line-height: 1;
    padding: 18px 46px;
    transition: all 0.3s ease 0s;
}
.subscribe2-wrapper .subscribe-form button i {
    font-size: 18px;
    padding-left: 5px;
}
.subscribe-area {
    margin-top: 10px;
}
